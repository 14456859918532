@import url('https://fonts.cdnfonts.com/css/mortal-kombat');

html,
body,
#root,
.app {
  height: 100%;
  width:  100%;
  font-family: "mortal kombat 4", sans-serif;
  /* overflow-y: hidden; */
}

.upload-img-text {
  text-align: center;
}

@media (min-width: 1000px) {
  .post-widget-container {
    /* Add your desired styles here */
    /* Example styles: */
    max-width: 750px;
    margin: 1rem auto;
  }
}

.shithead:hover{
  cursor: pointer;
}

img {
  max-width: 700px;
  max-height: 500px;
}



strong > span{

  color: skyblue;
  font-size: 2.5rem;
}



strong:hover{
  color: #8a0303;
}

.upload-vid-text {
  text-align:  center;
  color: skyblue;
}

.upload-vid-text:hover {
  color: #8a0303;
}


.kharacters {
  font-size: 1rem;
  color: #8a0303;
}

 video {
  max-width: 700px;
  max-height: 500px;
} 

.message {
  background-color: red !important; /* Background color for the message */
  color: black; /* Text color for the message */
  padding: 10px; /* Padding for the message */
  border: 12px solid blue; /* Border for the message */
  border-radius: 5px; /* Border radius for the message */
  margin: 10px 0; /* Margin for spacing */
  text-align: center;
  justify-content: center;
}



.current-events {
  text-align: center;
  color: red;
}

.scroll-to-top-button {
  float: right;
  background-color: skyblue;
  color: #8a0303;
}


button a.buy-with-crypto .connect-wallet{
  font-family: "Mortal Kombat 4";
}

.noUnderline {
  text-decoration: none;
}


/* CSS for modal */
.modal {
  /* display: none; */
  position: fixed;
  z-index: 1;
  /* right: 0; */
  top: 24%;
  background-image: url('./scenes/navbar/DOI13.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  left: -2%;
  transform: translate(-50%, -50%);
  width: auto; /* Set width to auto */
  height: auto; /* Set height to auto */
  background-color: rgba(0, 0, 0, 0.4); /* Black background with transparency */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .modal {
    width: 95%; 
    height: 75%; 
    top: 2%;
    left: 2%;
    
    

  }
}

/* Media query for tablets */
@media (min-width: 769px) and (max-width: 968px) {
  .modal {
    width: 95%; 
    height: 85%; 
    left: 12%;
  }
}

/* Media query for Desktop devices */
@media (min-width: 969px) and (max-width: 2000px) {
  .modal {
    width: 90%; 
    height: 80%;
    top: 20%; 
    left: 15%;
  }
}

/* @media (max-width: 968px) {
  .modal {
    width: 95%; 
    height: 85%; 
  }
} */

/* CSS for modal content */
.modal-content {
  position: relative;
  /* background-color: white; */
  margin: auto;
  padding: 20px;
  /* border: 1px solid #8a0303; */
  width: 100%;
  height: 100%; /* Allow the height to adjust based on content */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  
  
}



/* @media (max-width: 768px) {
  .modal-content img {
    position: absolute; 
    top: 20px; 
    right: 20px; 
    width: 350px; 
    height: 10px; 
  }
} */


/* Media query for Desktop devices */
@media (min-width: 969px) {
  .modal-content {
    width: 25%; 
    height: 40%;
    top: -2%;
    left: -2%; 
  }
}

@media (min-width: 969px) {
  .modal{
    width: 77%;
    margin-left: -30px; 
    height: 80%; 
    padding: 10px;

  }
}


.modal-header {
  cursor: move;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

@media (min-width: 769px) and (max-width: 968px) { 
  .modal-header{
    width: 70%;
    margin-left: 20px; 
    height: 60%; 
    padding: 10px;
  }

}

@media (max-width: 968px) {
  .modal-header{
    width: 20%; 
    height: 60%; 

  }
}

.modal-header .minimize, .close {
  color: #8a0303;
  font-size: 113px;
  font-weight: bold;
  cursor: pointer;
  /* margin-right: right; */
  justify-content: space-between;
  align-items: center;
  padding: 40px; /* Increase clickable area */



}

.modal-header .minimize:hover,
.modal-header .minimize:focus {
  color: skyblue;
  text-decoration: none;
}


.drag-handle {
  flex: 1; 
}


.minimize {
  color: #8a0303;
  font-size: 53px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 16px;
  position: absolute;
  z-index: 9999;
  top: -120px;
  left: -19px; 
}


.minimize:hover,
.minimize:focus {
  color: skyblue;
  text-decoration: none;
}

.modal.minimized {
  width: 200px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px; /* Adjust as needed */

}

.modal.minimized .modal-content {
  display: none; 
  
}


/* CSS for close button */
.close {
  position: absolute;
  top: -43px;
  right: -25px;
  color: #8a0303;
  font-size: 38px;
  font-weight: bold;
  z-index: 9999;
}

.close:hover,
.close:focus {
  color: skyblue;
  text-decoration: none;
  cursor: pointer;
}


/* CSS for purchase button */
.modal-content button {
  background-color: #8a0303;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  font-size: .9rem;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s; 
  margin-top: -5px; 
  margin-right: 40px;
}

/* Media query for tablets */
@media (min-width: 769px) and (max-width: 968px) {
  .modal-content {
    width: 58.6%; 
    height: 45%; 
    margin-left: 90px;
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .modal-content button {
 padding: 10px;
 width: 200px;
    margin-left: 130px;
    margin-top: 10px; 
    height: 50px; 


  }
}

@media (max-width: 768px)   {
  .custom-exit-button {
    padding: 6px 12px;
    margin-left: -10px;
  }
  
  .custom-exit-button-contained {
    margin-left: -20px;
  }
  

  }




/* Media query for tablets */
@media (min-width: 769px) and (max-width: 968px) {
  .modal-content button {
    margin-left: 10px;
    margin-top: 7px;
  }
}



@media (min-width: 601px) {
  .modal-content  {
 padding: 10px;
 width: 600px;
 height:400px; 
    margin-right: -20px;
    margin-top: 130px; 
    


  }
  .modal {
    width: 550px;
    height:650px; 
       margin-right: 20px;
       margin-top: -10px;  
  }
}




@media (max-width: 601px) {
  .modal-content  {
 padding: 10px;
 width: 400px;
 height:680px; 
    margin-right: -20px;
    margin-top: 15px; 
    


  }
  .modal {
    width: 360px;
    height:650px; 
       margin-right: 30px;
       margin-top: 5px;  
  }

 
  
}

@media (min-width: 770px) {
  .modal-content  {
 padding: 10px;
 width: 400px;
 height:380px; 
    margin-right: 50px;
    margin-top: 170px; 
    


  }
  .modal {
    width: 600px;
    height:650px; 
       margin-right: 30px;
       margin-top: -130px;  
  }
}

/* @media (min-width: 601px) {
  .modal-content{
    width: 200px;
    margin-left: -100px; 
    height: 50px; 
    padding: 10px;

  }
} */

.modal-content input {
  margin-bottom: 1px; 
}

/* Apply hover effect */
.modal-content button:hover {
  background-color: skyblue;
}

.modal-addr-content {
  margin-left: -10px;
}

/* Style the container for the input field and arrows */
.input-dark-wrapper {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start;
  margin-bottom: -80px; 
  float: left;
  margin-right: 820px;
}
/* Style the input field */
.input-dark {
  background-color: black;
  color: skyblue;
  font-size: large;
  font-weight: bold;
  height: 40px;
  border: 2px solid #8a0303;
  padding: 0 10px; 
  width: 336px;
  margin-right: 790px; 
  margin-top: 300px;

}

.input-dark-ERC20 {
  background-color: black;
  color: skyblue;
  font-size: large;
  font-weight: bold;
  height: 40px;
  border: 2px solid #8a0303;
  padding: 0 10px; 
  width: 336px;
  margin-right: -770px;
  margin-top: -50px;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .input-dark-ERC20 {
 padding: 10px;
 width: 304px;
    margin-left: 120px;
    margin-top: -10px; 
    height: 50px; 

  }
}

@media (min-width: 969px) {
  .input-dark-ERC20{
    width: 304px;
    margin-left: 20px; 
    height: 50px; 
    padding: 10px;

  }
}


.token-info p {
  text-align: left;
  font-size: 20px;
  color: #8a0303;
  background-color: white;
  width: 432px;
  margin-top: 1px;
  margin-left: 12px;


}

@media (max-width: 768px) {
  .token-info p {
 padding: 15px;
 width: 420px;
    margin-left: 120px;
    margin-top: -10px; 
    height: 70px; 

  }
}


@media (min-width: 969px) {
  .token-info p {
    width: 404px;
    margin-top: -15px;
    margin-left: 20px; 
    height: 70px; 
    padding: 10px;

  }
}




.token-info-span{
  color: skyblue;
  font-weight: 700;
  text-decoration: underline;
  background-color: black;


}
@media (max-width: 968px) {
  .token-info-span{
    width: 20%; 
    height: 60%; 

  }
}




  .action-button  {
  background-color: #8a0303;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s; 
  margin-right: 40px;

}






.action-button-wrapper  {
  margin-right: 520px;
  /* margin-bottom: 330px; */
  padding: 2px;
  width: 100%;
  margin-top: -180px;
}

@media (max-width: 768px) {
  .action-button-wrapper {
 padding: 10px;
 width: 10px;
    margin-left: -150px;
    margin-top: -630px; 
    height: 180px; 

  }
}


@media (min-width: 969px) {
  .action-button-wrapper {
    width: 404px;
    margin-top: -425px;
    margin-left: 20px; 
    height: 70px; 
    padding: 10px;

  }
}


.input-dark-wrapper-addr{
  padding: 10px;
  margin-top: 9px;
}

@media (max-width: 768px) {
  .input-dark-wrapper-addr{
    margin-top: 9px;

  }
}


@media (max-width: 768px) {
  .purchaseToken-btn {
 padding: 10px;
 width: 10px;
    margin-left: 80px;

    height: 180px; 

  }
}
.action-button:hover {
  background-color: skyblue;
} 


.purchaseToken-btn{
  margin-right: 330px;
  margin-top: -20px;

  padding-top: 10px;
  width: 100%;

}


@media (max-width: 768px) {
  .input-dark-wrapper {
    margin-top: -105px;
    margin-right: 140px;
  }

}


@media (max-width: 768px) {
  .purchaseToken-btn .purchaseToken-btn-buy {
    margin-top: 185px;
    height: 40px;
    width: 160px;
    right: 160px;
  }

}



.input-dark-purchase-btn {
  margin-top: 20px;
  background-color: black;
  color: skyblue;
  font-size: large;
  font-weight: bold;
  height: 40px;
  border: 2px solid #8a0303;
  padding: 0 10px; 
  width: 336px;
  margin-right: -770px;
  /* margin-top: -50px; */
  align-self: flex-start;
}

@media (max-width: 768px) {
  .input-dark-purchase-btn {
 padding: 5px;
 width: 370px;
    margin-left: 130px;
    margin-top: 166px; 
    height: 60px; 

  }
}


.action-balance-container {
  position: absolute;
  top: 10px; 
  right: -33px;
  width: 400px;
}

@media (max-width: 768px) {
  .action-balance-container {
 padding: 10px;
 
 width: 180px;
    margin-right: 100px;
    margin-top: 410px; 
    height: 80px; 

  }
}

.balance-info {
  font-size: 30px;
  position: relative;
  top: 80px; 
  right: 120px; 
  text-align: center; 
}

.balance-info{
  width: 394px;
  background-color: white;
  color: #8a0303;
  margin-left: 140px;
}

/* Media query for tablets */
@media (min-width: 769px) and (max-width: 968px) {
  .balance-info{
    font-size: 27px;
    margin-left: 2px;
    width: 525px;
    margin-top: 120px;
  }
}


/* Media query for Desktops */
@media (min-width: 969px) and (max-width: 1800px) {
  .balance-info{
    font-size: 27px;
    margin-left: 265px;
    width: 525px;
    margin-top: 60px;
  }
}




.action-balance-button {
  position: absolute;
  background-color: #8a0303;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s;
}

/* Media query for tablets */
@media (min-width: 769px) and (max-width: 968px) {
  button.action-balance-button {
    margin-left: 130px;
    width: 200px;
    margin-top: 147px;
  }
}


@media (max-width: 768px) {
  button.action-balance-button {
 padding: 10px;
 width: 160px;
    margin-left: -200px;
    margin-top: 220px; 
    height: 40px; 

  }
}


/* Media query for Desktop devices */
@media (min-width: 969px) {
  button.action-balance-button {
    top: 100px;
    left: 57%;
    width: 175px; 
  }
}



.balance-info-span {
  color: skyblue;
  background-color: black;
  font-weight: bold;
}

/* Media query for tablets */
@media (min-width: 769px) and (max-width: 968px) {
  .balance-info-span {
    margin-left: 70px;
    /* width: 50px;
    margin-top: -67px; */
  }
}

/* Media query for Desktop devices */
@media (min-width: 969px) {
  .balance-info {
    top: 170px;
    font-size: 23px;
    width: 140%;
    left: -25%; 
  }
}


/* Media query for Desktop devices */
@media (max-width: 768px) {
  .balance-info {
    top: 425%;
    width: 280%;
    height: 40px;
    font-size: 16px;
    left: -225%; 
    overflow: hidden;
  }
}


.transfer-container {
  position: relative; 
  margin: 0 auto;
  
}

/* Media query for tablets */
@media (min-width: 769px) and (max-width: 968px) {
  .transfer-container .transfer-btn {
    margin-left: 80px;
    margin-top: 127px; 
  }
}


@media (max-width: 768px) {
  .transfer-container {
 padding: 10px;
 width: -140px;
    margin-right: 600px;
    margin-top: -20px; 
    height: 20px; 

  }
}




.transfer-btn {
  position: absolute; 
  top: -120px;
  right: -350px; 
}

/* Media query for tablets */
@media (min-width: 769px) and (max-width: 968px) {
  .transfer-btn {
    margin-left: 130px;
    width: 180px;
  }
}

/* Media query for Desktop devices */
@media (min-width: 969px) {
  .transfer-container  {
    top: -23%;
    left: 27%; 
  }
}



@media (max-width: 768px) {
  .transfer-container .transfer-btn {
 padding: 10px;
 width: 160px;
    margin-right: 195px;
    margin-top: 389px; 
    height: 40px; 

  }
}

/* Media query for Desktop devices */
@media (min-width: 969px) {
  .transfer-container .transfer-btn  {
    top: 45%;
    left: 27%;
    width: 175px; 
  }
}



@media (min-width: 968px) {
  .transfer-container .transfer-btn {
 padding: 10px;
 width: 160px;
    margin-right: 95px;
    margin-top: 50px; 
    height: 40px; 

  }
}


.transfer-input-recipient {
  margin-bottom: 10px;
  margin-right: 100px;
  background-color: black;
  color: skyblue;
  font-size: large;
  font-weight: bold;
  height: 40px;
  border: 2px solid #8a0303;
  padding: 0 10px; 
  width: 336px;
  
}

.transfer-input-amount {
  margin-bottom: 10px;
  background-color: black;
  color: skyblue;
  font-size: large;
  font-weight: bold;
  height: 40px;
  border: 2px solid #8a0303;
  padding: 0 10px; 
  width: 336px;
}

.transfer-inputs{
  position: absolute; 
  top: 170px;
  right: -238px; 

}

/* Media query for tablets */
@media (min-width: 769px) and (max-width: 968px) {
  .transfer-inputs {
    margin-top: -100px;
    width: 100px;
    right: -12px;
  }
}

@media (max-width: 768px) {
  .transfer-inputs {
    top: 311px;
    right: -268px;
    padding: 2px;
    width: 280px; 

  }
}

/* Media query for Desktop devices */
@media (min-width: 969px) {
  .transfer-inputs  {
    top: 100px;
    left: -10px; 
  }
}

/* .modal-balance-content {
background-color: #8a0303;
  text-align: center;
  margin-right: 1200px;
  top: 0;

} */




/* .balance-info-span {
  position: absolute;
  top: 50px; 
  left: 220px; 
  font-size: 1.5rem;
  color: skyblue;
  background-color: black;


} */



/* Apply hover effect to arrows */
/* .input-dark::before:hover,
.input-dark::after:hover {
  background-color: skyblue;
} */


/* styles.css */
.boomeranged-dark {
  color: skyblue;
  text-decoration: underline;
}

.boomeranged-light {
  color: #8a0303;
  text-decoration: underline;
}
