#chirpContainer {
    position: relative;
    cursor: pointer;
  }
  
  .ring {
    display: none;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 3px solid transparent;
    border-radius: 50%;
    animation: none; /* Initial value, will be updated dynamically */
    animation: countdown 7s linear forwards, bounce 1s infinite alternate !important;
  }
  
  #chirpContainer.active .ring {
    display: block;
  }
  
  .paused .ring {
    animation-play-state: paused !important;
  }
  
  .paused .ring::before,
  .paused .ring::after {
    animation-play-state: paused;
  }
  
  .ring::before,
  .ring::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid transparent;
    border-radius: 50%;
    animation: ringDisappear 7s linear forwards;
  }
  
  @keyframes ringDisappear {
    0% {
      border-color: green;
      opacity: 1;
    }
    100% {
      border-color: red;
      opacity: 0;
    }
  }
  
  @keyframes countdown {
    0% {
      border-color: green;
      border-width: 3px;
      opacity: 1; /* Fully visible at the start */
    }
    100% {
      border-color: red;
      opacity: 0; /* Completely invisible at the end */
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .restart-animation {
    animation: countdown 7s linear forwards, bounce 1s infinite alternate !important;
  }
  

  .post-widget-container {
    position: relative;
    transition: transform 0.5s;

  }
  
  .post-widget-container.flipped {
    transform: rotateY(180deg);
  }
  

  .managed-pointer {
    cursor: pointer;
  }
  