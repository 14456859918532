.pdf-container {
    position: relative;
}

.pdf-container .MuiButton-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    background-color: #8a0303;
    white-space: nowrap;
    padding: 8px 16px;
  }
  
  .pdf-container:hover .MuiButton-root {
    display: block;
    background-color: #8a0303;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
  }
  
  .next-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  